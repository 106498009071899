<template>
  <section class="bg-logo">
    <div class="container min-vh-100">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="card top-50 p-3">
            <div class="card-body">
              <form @submit.prevent="handleLogin">
                <h3 class="text-center">登入</h3>
                <div class="mb-3">
                  <label for="js-loginAccount" class="form-label">帳號</label>
                  <input
                    id="js-loginAccount"
                    data-test="accountInput"
                    type="text"
                    class="form-control border"
                    v-model.trim="login.account"
                    aria-describedby="accountHelp"
                    required
                  />
                </div>
                <div class="mb-3">
                  <label for="js-loginPassword" class="form-label">密碼</label>
                  <input
                    id="js-loginPassword"
                    data-test="passwordInput"
                    type="password"
                    class="form-control"
                    v-model.trim="login.password"
                    required
                  />
                </div>
                <button
                  type="submit"
                  class="btn btn-primary text-white m-auto d-block"
                  :disabled="!isButtonValid"
                >
                  送出
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import JWT from "@/common/cookies";
import { apiLogIn } from "@/api";

export default {
  data() {
    return {
      login: {
        account: "",
        password: "",
      },
    };
  },
  computed: {
    isButtonValid() {
      return this.login.account && this.login.password;
    },
  },
  methods: {
    async handleLogin() {
      this.$vLoading(true);
      try {
        const res = await apiLogIn(this.login);
        const { code, data, message } = res.data;
        if (code === 200) {
          const { token, expireTime, refreshToken } = data;
          JWT.saveToken(token, expireTime);
          const endOfToday = `${this.$utilsFilters.dateStr()} 23:59:59`;
          JWT.saveRefreshToken(refreshToken, endOfToday);
          this.$router.push("/admin");
        } else {
          this.$vHttpsNotice({ statusCode: code, title: message });
        }
      } catch (error) {
        this.$vErrorNotice();
      } finally {
        this.$vLoading(false);
      }
    },
  },
};
</script>
